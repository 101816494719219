const dev = {
  API_BASE_URL: "https://localhost:5000/api/",
  CLIENT_BASE_URL: "https://localhost:3000/"
};

const prod = {
  API_BASE_URL: "https://backend.zumaridiafrica.com/api/",
  CLIENT_BASE_URL: "https://zumaridi.vercel.app/"
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    default:
      break;
  }
};

export const env = getEnv();
