import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { getInitials } from "../../utils";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center"
  },
  initialWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
    background: "#07AA3D",
    marginRight: 8,
    borderRadius: 11
  },
  initials: {
    color: "white",
    fontSize: 18
  },
  username: {
    fontSize: "27px",
    color: "#48484A"
  }
});

const DisplayName = ({ displayName, white }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.initialWrapper}>
        <Typography variant="h5" className={classes.initials}>
          {getInitials(displayName)}
        </Typography>
      </div>
      <Typography variant="h4" className={classes.username} style={{ color: white && "white" }}>
        {displayName}
      </Typography>
    </div>
  );
};

export default DisplayName;
